import React, { useContext }  from "react";
import BuyerPortalStats from "../@Pages/BuyerPortalStats";
import { currency, float, percent, thousands } from "../@Utils/Format";
import { Table } from "rsuite";
// import SideBar from "./Dashboard/Sidebar/SideBar";
import 'rsuite/dist/rsuite.min.css';

const { Cell } = Table;

export const HourCell = ({ rowData, dataKey, ...props }: any) => {
  const hours =
      parseInt(rowData[dataKey]) < 10 ? "0" + rowData[dataKey] : rowData[dataKey];
  return <Cell {...props}>{hours + ":00"}</Cell>;
};

export const NumberCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
);

export const CurrencyCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props}>{currency(rowData[dataKey])}</Cell>
);

export const PercentCell = ({
rowData,
dataKey,
digits = 1,
...props
}: any) => <Cell {...props}>{percent(rowData[dataKey], digits)}</Cell>;


export const HeaderSummary = ({ title, summary }: any) => (
    <div className={"adm-rs-table-cell-header-summary-wrapper"}>
      <label>{title}</label>
      <div className={"adm-rs-table-cell-header-summary-content"}>
        {thousands(summary || 0)}
      </div>
    </div>
);





// // Create a state
// const [filterInput, setFilterInput] = React.useState("");
//
// // Update the state when input changes
// const handleFilterChange = (e: any) => {
//   const value = e.target.value || undefined;
//   setFilterInput(value);
// };
//
// // Input element
// <input
//   value={filterInput}
//   onChange={handleFilterChange}
//   placeholder={"Search name"}
// />