export const thousands = (value: string) =>
    `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, "$1,");

export const float = (value: number | String = 0, digits: number = 2) =>
    Number(value).toFixed(digits);

export const currency = (value: number = 0) =>
    value.toLocaleString("en-US", { style: "currency", currency: "USD" });

export const percent = (value: number | String = 0, digits: number = 1) =>
    float(value, digits) + "%";
