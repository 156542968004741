import React from 'react';
import './App.css';
import { Outlet } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BuyerPortalStatsPage from "./@Pages/BuyerPortalStats";
import SignInPage from "./@Pages/SignIn";
import {PortalProvider} from "./@Context/PortalContext";


function App() {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('authorization_key');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        setIsLoggedIn(true);
    }
    React.useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
  return (
      <React.Fragment>
        <PortalProvider>
        <Outlet/>
        </PortalProvider>
              
        
      </React.Fragment>

  );
}

export default App;
