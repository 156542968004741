import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
const ProtectedRoute = (props:any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('authorization_key');
        const userUid = localStorage.getItem('uid');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/auth/login');
        }
        setIsLoggedIn(true);
    }


//     let hours = 1; // to clear the localStorage after 1 hour
//     // (if someone want to clear after 8hrs simply change hours=8)
// let now = new Date().getTime();
// let setupTime = localStorage.getItem('setupTime');
// if (setupTime == null) {
// localStorage.setItem('setupTime', String(now))
// } else {
// if(Number(setupTime) > hours*60*60*1000) {
// localStorage.clear()
// localStorage.setItem('setupTime', String(now));
// }
// }

    useEffect(() => {
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedRoute;