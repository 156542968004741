import React from "react";
import {parseISO, startOfMonth} from "date-fns";

const LOCAL_STORAGE_KEYS = {
    DATES: 'filters.dates',
    AFFILIATE_ID: 'filters.affiliate_id',
    CAMPAIGN_ID: 'filters.campaign_id'
};

interface PortalProviderProps {
    children?: React.ReactNode;
}

type PortalContextType = {
    start: Date;
    end: Date;
    affiliate_id: any;
    setDateRange?: any;
    setAffiliateId?: any;
    timeZone?: any;
    setTimeZone?: any;
    campaign?: any;
    setCampaign?: any;
};

const PortalContext = React.createContext<PortalContextType>(
    {
        start: startOfMonth(new Date()),
        end: new Date(),
        affiliate_id: '',
        timeZone: '',
        campaign: ''
    }
);

/**
 * Helper function for recording data to local storage
 * @param localStorageKey
 */
const useStoredDates = () => {
    const storedValue = sessionStorage.getItem(LOCAL_STORAGE_KEYS.DATES);

    // Default value for today's date
    const initialValue = {
        start: startOfMonth(new Date()),
        end: new Date(),
    };

    /**
     * Helper function for parsing storage date range filters
     * @param value
     */
    const parseStoredValue = (value: any) => {
        const parsed = JSON.parse(value);
        parsed.start = parseISO(parsed.start);
        parsed.end = parseISO(parsed.end);
        return parsed;
    };

    // Set state via useState Hook
    const [value, setValue] = React.useState(
        !storedValue ? initialValue : parseStoredValue(storedValue)
    );

    // Save values to local storage once state updates
    React.useEffect(() => {
        sessionStorage.setItem(LOCAL_STORAGE_KEYS.DATES, JSON.stringify(value));
    }, [value]);
    return [value, setValue];
};

/**
 * Helper function for recording misc data to local storage
 */
const useStoredValues = (localStorageKey: string, initialValue: any) => {
    const storedValue = localStorage.getItem(localStorageKey);

    /**
     * Helper function for parsing storage date range filters
     * @param value
     */
        // Set state via useState Hook
    const [value, setValue] = React.useState(
            !storedValue ? initialValue : JSON.parse(storedValue)
        );

    // Save values to local storage once state updates
    React.useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value, localStorageKey]);
    return [value, setValue];
};

const PortalProvider: React.FC<PortalProviderProps> = (props) => {
    const [Portal, setPortal] = useStoredDates();
    const [Affiliate, setAffiliateId] = useStoredValues(
        LOCAL_STORAGE_KEYS.AFFILIATE_ID,
        localStorage.getItem('affiliate_id')
    );
    const [TimeZone, setTimeZone] = React.useState('PT');
    const [Campaign, setCampaign] = useStoredValues(
        LOCAL_STORAGE_KEYS.CAMPAIGN_ID,
        null
    )

    return (
        <PortalContext.Provider
            value={{
                start: Portal.start,
                end: Portal.end,
                affiliate_id: Affiliate,
                setDateRange: setPortal,
                setAffiliateId,
                timeZone: TimeZone,
                setTimeZone,
                campaign: Campaign,
                setCampaign
            }}
        >
            {props.children}
        </PortalContext.Provider>
    )
};

export { PortalProvider, PortalContext };