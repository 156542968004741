import React from "react";
import HttpClient from "../../@Utils/HttpClient";
import {Table, FlexboxGrid, Pagination, IconButton, Whisper, Popover, Dropdown, Nav} from 'rsuite';
import {PortalContext} from "../../@Context/PortalContext";
import {Line, LineChart} from "@rsuite/charts";
import { sort } from "../../@Utils/Sorting"
import {format} from "date-fns";
import { percent, currency, thousands, float } from "../../@Utils/Format";
import { exportCsv } from "../../@Utils/Export";
import { HeaderSummary } from "../Table";
import { FaEllipsisV } from 'react-icons/fa';
import moment from 'moment';
const { Column, HeaderCell, Cell } = Table;


const DailyStatsTable: React.FC = (
) => {
    const [isLoadingDay, setLoadingDay] = React.useState(false);
    const [data, setData] = React.useState<any[]>([]);
    const Portal = React.useContext(PortalContext);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [limit, setLimit] = React.useState(100);
    const [page, setPage] = React.useState(1);
    const [action, setAction] = React.useState(0);

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    /**
     * @param list
     */
    const sortData = (list: any) => {
        return sortColumn && sortType ? sort(list, sortColumn, sortType) : list

    };

    const formatData = (data: any[]) => {
        return data.map((item: any) => {
            return {
                ...item,
                paid: Number(parseFloat(item.paid).toFixed(2)),
                leads: Number(parseFloat(item.leads).toFixed(2)),
                purchased: Number(parseFloat(item.purchased).toFixed(2)),
                accepted_percent: Number(item.leads !== 0 ? (parseFloat(item.purchased) / parseFloat(item.leads) * 100).toFixed(2) : 0)
            }
        })
    }

    const fetchDailyData = (noCache = true) => {
        // Clear and mask a table
        setData([]);
        setLoadingDay(true);

        const params = {
            start_date: format(Portal.start, "yyyy-MM-dd 00:00:00"),
            end_date: format(Portal.end, "yyyy-MM-dd 23:59:59"),
            time_zone: Portal.timeZone,
            afid: Portal.affiliate_id,
            group_by: 'day',
            campaign: Portal.campaign ? Portal.campaign : '',
            authorization_key: localStorage.getItem('authorization_key')
        }

        HttpClient
            .get<any>('buyer_stats', params, noCache)
            .then(res => {
                setLoadingDay(false);
                setData(res.data.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    };

    React.useEffect(() => {
        fetchDailyData()
    }, [Portal.start, Portal.end, Portal.affiliate_id, Portal.timeZone, Portal.campaign]);

    React.useEffect(() => {
        handleSortColumn('date', 'desc');
    },[])

    let lineArray:any = [];
    const lineChartData = Array.isArray(data) ? data : [];
    const lineData = lineChartData.slice(0).reverse().map((item:any, idx) => {
        lineArray = [item.date, item.leads, String(item.purchased)];
        return lineArray;
    });

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
    };

    const tableData = Array.isArray(data) ?
        data.filter((v:any, i:any) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        }) : []


    const formatExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        "Date":  moment(item.date).format("YYYY-MM-DD"),
                        "Leads": thousands(item.leads),
                        "Purchased": thousands(item.purchased),
                        "Paid": currency(item.paid),
                        "Accepted Percent": item.accepted_percent
                    }
                })
        );
    };

    const getSummary = (data: any) => {
        const summary: { [p: string]: number } = {};
        const summarizer = (accumulator: number, currentValue: number) =>
            accumulator + currentValue;

        const summaryFields = [
            "leads",
            "purchased",
            "paid",
            "accepted_percent",
        ];
        summaryFields.forEach((field) => {
            summary[field] = 0;
        });

        summaryFields.forEach((field: string) => {
            let values = data.map((item: { [index: string]: string }): number => {
                return parseFloat(item[field]);
            });
            summary[field] = values.reduce(summarizer, 0) || 0;
        });

        return summary;
    }

    const summaryData = getSummary(Array.isArray(data) ? formatData(data) : [])



    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12} style={{ textAlign: "right" }}>
                    <Whisper
                        placement="auto"
                        trigger="click"
                        speaker={(
                            {
                                onClose,
                                left,
                                top,
                                className
                            }, ref) => {
                            const handleSelect = (eventKey: any) => {
                                onClose();
                                setAction(eventKey);
                            };
                            return (
                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                    <Dropdown.Menu onSelect={handleSelect}>
                                        <Dropdown.Item
                                            onClick={() => {
                                                exportCsv(
                                                    `PubId_${Portal.affiliate_id}_${format(new Date(Portal.start), "MMddyy")}
                                                                _${format(new Date(Portal.end), "MMddyy")}.csv`,
                                                    formatExportData(formatData(data))
                                                )
                                            }}
                                        >
                                            Export
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Popover>
                            );
                        }}
                    >
                        <IconButton appearance="primary" icon={<FaEllipsisV />} />
                    </Whisper>
                    <Table
                        bordered
                        loading={isLoadingDay}
                        cellBordered
                        // height={300}
                        headerHeight={40}
                        data={Array.isArray(tableData) ? sortData(formatData(tableData)) : []}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        rowHeight={20}
                    >
                        <Column width={112} align="center" flexGrow={1} fixed sortable>
                            <HeaderCell style={{ }}>Date</HeaderCell>
                            <Cell dataKey="date" />
                        </Column>
                        <Column width={70} align="center" flexGrow={1} fixed sortable>
                            <HeaderCell>
                                <HeaderSummary title="Leads" summary={thousands(summaryData.leads.toString())}/>
                            </HeaderCell>
                            <Cell dataKey="leads" />
                        </Column>
                        <Column width={130} align="center" flexGrow={1} fixed sortable>
                            <HeaderCell>
                                <HeaderSummary title="Purchased" summary={thousands(summaryData.purchased.toString())}/>
                            </HeaderCell>
                            <Cell dataKey="purchased" />
                        </Column>
                        <Column width={100} align="center" flexGrow={1} fixed sortable>
                            <HeaderCell>
                                <HeaderSummary title="Paid" summary={currency(summaryData.paid)}/>
                            </HeaderCell>
                            <Cell dataKey="paid" />
                        </Column>
                        <Column width={100} align="center" flexGrow={1} fixed sortable>
                            <HeaderCell>
                                % Accepted
                            </HeaderCell>
                            <Cell dataKey="accepted_percent" />
                        </Column>
                    </Table>
                    <div style={{ padding: 2,  }}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['total', '-', 'limit', 'pager']}
                            total={data.length}
                            limitOptions={[10, 30, 50, 100]}
                            limit={limit}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <LineChart name="" data={Array.isArray(lineData) ? lineData : []} height={ 350 }>
                        <Line name="Leads" />
                        <Line name="Purchased" />
                    </LineChart>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};


export default DailyStatsTable;