import React from "react";
import HttpClient from "../@Utils/HttpClient";
import {
    Container,
    Header,
    Content,
    Nav,
    Navbar,
    FlexboxGrid,
    Panel,
    Form,
    ButtonToolbar,
    Button,
    Footer,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PortalContext } from "../@Context/PortalContext";

let initialValues = {
    email: ''
  }

interface ResetProps {
    children?: React.ReactNode;
  }


  
  const ResetSubmit: React.FC<ResetProps> = (props) => {
      const Portal = React.useContext(PortalContext);



    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [authenticated, setAuthenticated] = React.useState(localStorage.getItem("authenticated"));
    // const [affiliateId, setAffiliateId] = React.useState("1891");
    const [password, setPassword] = React.useState("");
    const [passwordConfirm, setPasswordConfirm] = React.useState("");
    const [searchParams] = useSearchParams();
    const [passwordSent, setPasswordSent] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [passwordClear, setPasswordClear] = React.useState(false);
    const [passwordConfirmPass, setPasswordConfirmPass] = React.useState(false);

    const [uppercaseConfirm, setUppercaseConfirm] = React.useState('');
    const [lowercaseConfirm, setLowercaseConfirm] = React.useState('');
    const [hasNumberConfirm, setHasNumberConfirm] = React.useState('');
    const [isLengthConfirm, setIsLengthConfirm] = React.useState('');
    const [confirmSuccess, setConfirmSuccess] = React.useState('')
      const affid = Portal.affiliate_id;

    const handleChange = (e:any) => {
        document.getElementById('email')?.classList.remove('error');
        
        setEmail(e);
        
    }

    // const handlePass = (passwordEntry:any) => {
    //     document.getElementById('password')?.classList.remove('error');
    //     setPassword(passwordEntry);
    //
    //
    //
    //      // regular expressions to validate password
    //      const lowerCase = /[a-z]/g;
    //      const upperCase = /[A-Z]/g;
    //      const numbers = /[0-9]/g;
    //
    //     passwordEntry.match(lowerCase) ? setLowercaseConfirm('green') : setLowercaseConfirm('red');
    //     passwordEntry.match(upperCase) ? setUppercaseConfirm('green') : setUppercaseConfirm('red');
    //     passwordEntry.match(numbers) ? setHasNumberConfirm('green') : setHasNumberConfirm('red');
    //     passwordEntry.length < 7 ? setIsLengthConfirm('red') : setIsLengthConfirm('green');
    //
    //     !passwordEntry.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) ? setPasswordClear(false) : setPasswordClear(true)
    //
    //
    //     console.log(password)
         //
         //
         // if (!passwordEntry.match(lowerCase)) {
         //     setErrorMessage("Password should contains lowercase letters!");
         //
         //     setPasswordClear(false);
         // } else if (!passwordEntry.match(upperCase)) {
         //     setErrorMessage("Password should contain uppercase letters!");
         //     setPasswordClear(false);
         // } else if (!passwordEntry.match(numbers)) {
         //     setErrorMessage("Password should contains numbers also!");
         //     setPasswordClear(false);
         // } else if (!passwordEntry.match(email)) {
         //    setErrorMessage("Password cannot be your email address!");
         //    setPasswordClear(false);
         // } else if (passwordEntry.length < 7) {
         //     setErrorMessage("Password length should be more than 7.");
         //     setPasswordClear(false);
         // } else {
         //     setErrorMessage("Password is strong!");
         //     setPasswordClear(true);
         // }
    // }

    // const handlePassConfirm = (e:any) => {
    //     document.getElementById('confirmPassword')?.classList.remove('error');
    //     setPasswordConfirm(e);
    //
    //     password !== e ? setPasswordConfirmPass(false) : setPasswordConfirmPass(true);
    //
    //
    //
    //     if (!password.match(passwordConfirm)) {
    //         setErrorMessage("Confirm Password should match password!");
    //         setPasswordClear(false);
    //     } else {
    //         setErrorMessage("Password is strong!");
    //         setPasswordClear(true);
    //     }
    // }

      React.useEffect(() => {
          const lowerCase = /[a-z]/g;
          const upperCase = /[A-Z]/g;
          const numbers = /[0-9]/g;

          password.match(lowerCase) ? setLowercaseConfirm('green') : setLowercaseConfirm('red');
          password.match(upperCase) ? setUppercaseConfirm('green') : setUppercaseConfirm('red');
          password.match(numbers) ? setHasNumberConfirm('green') : setHasNumberConfirm('red');
          password.length < 7 ? setIsLengthConfirm('red') : setIsLengthConfirm('green');

          !password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) ? setPasswordClear(false) : setPasswordClear(true)
      }, [password])

    React.useEffect(() => {
        passwordConfirm !== password ? setPasswordConfirmPass(false) : setPasswordConfirmPass(true);

        passwordConfirm === password ? setConfirmSuccess('green') : setConfirmSuccess('red');
    }, [passwordConfirm])

    const onSubmit = (e:any) => {
        e.preventDefault();

        if (passwordClear && passwordConfirmPass) {

            HttpClient
            .get<any>('https://admin.admediary.com/api/reportsexternal.php?op=password_reset&uid='+localStorage.getItem("uid")+'&email='+localStorage.getItem("email")+'&affiliate_id='+affid+'&p='+password)
            .then(res => {
                if (res.data.response_data.row_count > 0) {
                    localStorage.clear();
                    setPasswordSent(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 6000);
                    
                } else {
                    document.getElementById('email')?.classList.add('error');
                    
                }
                
            })
            .catch(error => {
                console.log('error', error)
            })
        }
    };

    return (
        <div>
            <Container>
                <Header>
                </Header>
                <div style={{ textAlign: "center" }}>
                    <h2>Portal Login</h2>
                    {/* <h4 style={{ color: "green" }}>
                        React Suite Container Login Page Layout
                    </h4> */}
                </div>
                <Content>
                    <FlexboxGrid justify="center" 
                        style={{ margin: 20 }}>
                        <FlexboxGrid.Item>
                            <Panel header={<h3>Password Reset</h3>} bordered>
                                <Form>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Email
                                        </Form.ControlLabel>
                                        <p>{localStorage.getItem('email')}</p>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Password
                                        </Form.ControlLabel>
                                        <Form.Control name="password" id="password"
                                            type="password" onChange={(v: any) => setPassword(v)} style={{borderColor: confirmSuccess}}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Confirm Password
                                        </Form.ControlLabel>
                                        <Form.Control name="confirmpassword" id="confirmpassword"
                                            type="password" onChange={(v: any) => setPasswordConfirm(v)} style={{borderColor: confirmSuccess}} />
                                    </Form.Group>
                                    <span>
                                        <span>Password Requires:</span>  <br/>
                                        <span style={{color: uppercaseConfirm}}>** 1 Uppercase Letter</span> <br/>
                                        <span style={{color: lowercaseConfirm}}>** 1 Lowercase Letter</span> <br/>
                                        <span style={{color: hasNumberConfirm}}>** 1 Number</span><br/>
                                        <span style={{color: isLengthConfirm}}>** At Least 7 Characters Long</span><br/>
                                         <span style={{color: 'red', display: !passwordConfirmPass ? 'block' : 'none'}}>** Passwords Must Match</span>
                                    </span>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button appearance="primary" onClick={onSubmit}>
                                                Submit
                                            </Button>
                                            <Button appearance="ghost">
                                                Cancel
                                            </Button>
                                        </ButtonToolbar>

                                        {/*{errorMessage ? (*/}
                                        {/*    <span style={{color: 'red'}}>{errorMessage}</span>*/}
                                        {/*) : ''}*/}
                                        {passwordSent ? (
                                            <span style={{color: 'red'}}>**Your password has been updated! You will now be redirected to the login page.**</span>
                                        ) : ''}
                                    </Form.Group>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <Footer style={{ textAlign: "center", margin: 20 }}>
                    © Admediary
                </Footer>
            </Container>
        </div>
    );
}
  
  export default ResetSubmit;