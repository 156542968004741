import React from "react";
import HttpClient from "../@Utils/HttpClient";
import {
    Container,
    Header,
    Content,
    FlexboxGrid,
    Panel,
    Form,
    ButtonToolbar,
    Button,
    Footer,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";
import {PortalContext} from "../@Context/PortalContext";

interface SignInProps {
    children?: React.ReactNode;
}

const SignIn: React.FC<SignInProps> = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [authenticated, setAuthenticated] = React.useState(localStorage.getItem("authenticated"));

    const handleChange = (e:any) => {
        document.getElementById('email')?.classList.remove('error');
        
        setEmail(e);
    };

    const handlePass = (e:any) => {
        document.getElementById('password')?.classList.remove('error');
        setPassword(e);
    };

    const onReset = (e:any) => {
        navigate('/auth/reset');
    };

    const onSubmit = (e:any) => {
        e.preventDefault();

        HttpClient
        .get<any>('https://admin.admediary.com/api/reportsexternal.php?op=signin&email='+email+'&p='+password)
        .then(res => {
            if (res.data.response_data.row_count > 0) {
                localStorage.clear();
                localStorage.setItem('authorization_key', res.data.response_data.data[0].authorization_key);
                localStorage.setItem('email', res.data.response_data.data[0].email);
                localStorage.setItem('affiliate_name', res.data.response_data.data[0].affiliate_name)
                localStorage.setItem('affiliate_id', res.data.response_data.data[0].affiliate_id)

                let expires = (new Date(Date.now()+ 86400*1000)).toUTCString();
                document.cookie = "authorization_key="+res.data.response_data.data[0].authorization_key+"; expires=" + expires + ";path=/;"

                if(localStorage.getItem('authorization_key')) {
                    navigate('/');
                }
            } else {
                document.getElementById('email')?.classList.add('error');
                document.getElementById('password')?.classList.add('error');
            }
            
        })
        .catch(error => {
            console.log('error', error)
        })
    };

    const handleEnter = (key: any) => {
        if (key.keyCode === 13) {
            onSubmit(key)
        }
    }

    return (
        <div>
            <Container>
                <Header>
                    {/* <Navbar appearance="inverse" 
                        style={{ backgroundColor: "green" }}>
                        <Navbar.Brand>GeeksforGeeks</Navbar.Brand>
                        <Nav>
                            <Nav.Item>Home</Nav.Item>
                            <Nav.Item>About</Nav.Item>
                            <Nav.Item>Practice</Nav.Item>
                            <Nav.Menu title="Tutorials">
                                <Nav.Item>Data Structures</Nav.Item>
                                <Nav.Item>Algorithms</Nav.Item>
                                <Nav.Item>Web Development</Nav.Item>
                            </Nav.Menu>
                        </Nav>
                        <Nav pullRight>
                            <Nav.Item>Contact</Nav.Item>
                        </Nav>
                    </Navbar> */}
                </Header>
                <div style={{ textAlign: "center" }}>
                    <h2>Portal Login</h2>
                    {/* <h4 style={{ color: "green" }}>
                        React Suite Container Login Page Layout
                    </h4> */}
                </div>
                <Content>
                    <FlexboxGrid
                        justify="center"
                        style={{ margin: 20 }}
                    >
                        <FlexboxGrid.Item>
                            <Panel
                                header={<h3>Login</h3>}
                                bordered
                            >
                                <Form>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Email
                                        </Form.ControlLabel>
                                        <Form.Control
                                            name="email"
                                            id="email"
                                            type="email"
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.HelpText tooltip>
                                            Required
                                        </Form.HelpText>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Password
                                        </Form.ControlLabel>
                                        <Form.Control
                                            name="password"
                                            id="password"
                                            type="password"
                                            onChange={handlePass}
                                            onKeyUp={handleEnter}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button
                                                appearance="primary"
                                                onClick={onSubmit}
                                            >
                                                Sign in
                                            </Button>
                                            <Button
                                                appearance="ghost"
                                                onClick={onReset}
                                            >
                                                Forgot Password
                                            </Button>
                                        </ButtonToolbar>
                                    </Form.Group>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <Footer
                    style={{ textAlign: "center", margin: 20 }}
                >
                    © {(new Date().getFullYear())} Admediary
                </Footer>
            </Container>
        </div>
    );
}
  
  export default SignIn;