import React from "react";
import HttpClient from "../../@Utils/HttpClient";
import {Pagination, Table, IconButton, Whisper, Popover, Dropdown, FlexboxGrid} from 'rsuite';
import {PortalContext} from "../../@Context/PortalContext";
import { sort } from "../../@Utils/Sorting"
import { exportCsv } from "../../@Utils/Export";
import { FaEllipsisV } from 'react-icons/fa';
import {thousands} from "../../@Utils/Format";
import {format} from "date-fns";
import moment from 'moment';

const { Column, HeaderCell, Cell } = Table;

const RejectedStatsTable: React.FC = () => {
    const Portal = React.useContext(PortalContext);
    const [data, setData] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sortColumn, setSortColumn] = React.useState("date");
    const [sortType, setSortType] = React.useState();
    const [limit, setLimit] = React.useState(100);
    const [page, setPage] = React.useState(1);
    const [action, setAction] = React.useState(0);

    const formatData = (data: any[]) => {
        return data.map((item: any) => {
            return {
                ...item,
                lead_date_time:  moment(item.lead_date_time).format("YYYY-MM-DD HH:mm:ss")
            }
        })
    }

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    /**
     * @param list
     */
    const sortData = (list: any) => {
        return sortColumn && sortType ? sort(list, sortColumn, sortType) : list

    };

    const fetchRejectedData = (noCache = true) => {
        // Clear and mask a table

        setIsLoading(true);
        setData([])


        const params = {
            start_date: format(Portal.start, "yyyy-MM-dd 00:00:00"),
            end_date: format(Portal.end, "yyyy-MM-dd 23:59:59"),
            time_zone: Portal.timeZone,
            afid: Portal.affiliate_id,
            was_paid: 0,
            campaign: Portal.campaign ? Portal.campaign : '',
            authorization_key: localStorage.getItem('authorization_key')
        }

        HttpClient
            .get<any>('buyer_stats', params, noCache)
            .then(res => {
                setIsLoading(false);
                setData(res.data.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    }


    React.useEffect(() => {
        fetchRejectedData();
    }, [Portal.start, Portal.end, Portal.affiliate_id, Portal.timeZone, Portal.campaign]);

    React.useEffect(() => {
        handleSortColumn('lead_date_time', 'desc');
    },[])

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
    };

    const tableData = Array.isArray(data) ?
        data.filter((v:any, i:any) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        }) : [];

    const formatExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        "Date": moment(item.lead_date_time).format("YYYY-MM-DD HH:mm:ss"),
                        "ID": item.lead_instance_id,
                        "Campaign": item.description,
                        "Email": item.email,
                        "SubId": item.subid1,
                        "Disposition": item.disposition,
                    }
                })
        );
    };

    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
                    <Whisper
                        placement="auto"
                        trigger="click"
                        speaker={(
                            {
                                onClose,
                                left,
                                top,
                                className
                            }, ref) => {
                            const handleSelect = (eventKey: any) => {
                                onClose();
                                setAction(eventKey);
                            };
                            return (
                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                    <Dropdown.Menu onSelect={handleSelect}>
                                        <Dropdown.Item
                                            onClick={() => {
                                                exportCsv(
                                                    `Rejected_Leads_PubId_${Portal.affiliate_id}_${format(new Date(Portal.start), "MMddyy")}
                                                                _${format(new Date(Portal.end), "MMddyy")}.csv`,
                                                    formatExportData(data)
                                                )
                                            }}
                                        >
                                            Export
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Popover>
                            );
                        }}
                    >
                        <IconButton appearance="primary" icon={<FaEllipsisV />} style={{marginRight: 60}} />
                    </Whisper>

                <Table
                    bordered
                    loading={isLoading}
                    cellBordered
                    // height={320}
                    style={{ width: '96%'}}
                    headerHeight={40}
                    data={Array.isArray(tableData) ? sortData(formatData(tableData)) : []}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    rowHeight={20}
                >
                    <Column width={112} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>Lead Date</HeaderCell>
                        <Cell dataKey="lead_date_time" />
                    </Column>
                    <Column width={40} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>Lead Instance Id</HeaderCell>
                        <Cell dataKey="lead_instance_id" />
                    </Column>
                    <Column width={130} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>Campaign</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>
                    <Column width={220} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column width={100} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>SubId</HeaderCell>
                        <Cell dataKey="subid1" />
                    </Column>
                    <Column width={100} align="center" flexGrow={1} fixed sortable>
                        <HeaderCell>Disposition</HeaderCell>
                        <Cell dataKey="disposition" />
                    </Column>
                </Table>
                <div style={{ padding: 2, marginRight: 50 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', 'pager']}
                        total={data.length}
                        limitOptions={[10, 30, 50, 100]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                    />
                </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </>
    );
};

export default RejectedStatsTable;