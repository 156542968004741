import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './@Auth/SignIn';
import Reset from './@Auth/Reset';
import ResetVerify from './@Auth/ResetVerify';
import ResetSubmit from './@Auth/ResetSubmit';
import Auth from './@Auth/Auth';
import ProtectedRoute from './@Utils/ProtectedRoute';
import Home from './@Pages/BuyerPortalStats';
import {PortalProvider} from "./@Context/PortalContext";

ReactDOM.render(
    <React.StrictMode>
        <PortalProvider>
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path='/auth' element={<Auth />}>
                    <Route path='login' element={<Login />} />
                    <Route path='reset' element={<Reset />} />
                    <Route path='resetverify' element={<ResetVerify />} />
                    <Route path='resetsubmit' element={<ResetSubmit />} />
                </Route>
                <Route path="/" element={<App />}>
                    <Route path='' element={
                        <ProtectedRoute>
                            <Home />
                            {/* <ResetVerify />
                            <ResetSubmit /> */}
                        </ProtectedRoute>
                    } />
                </Route>
            </Routes>
        </BrowserRouter>
        </PortalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
