/**
 * Helper function for sorting arrays
 *
 * @param attribute
 * @param data
 * @param type
 */
export const sort = (data: any[], attribute: string | number, type?: string): any[] => {
    // descending order
    if (type === "desc") {
        return data.sort((a: any, b: any) => {
            return a[attribute] > b[attribute] ? -1 : 1;
        });
    }
    // ascending order
    return data.sort((a: any, b: any) => {
        return a[attribute] > b[attribute] ? 1 : -1;
    });
};