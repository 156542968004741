import React from "react";
import HttpClient from "../../@Utils/HttpClient";
import {
    Table,
    Pagination,
    IconButton,
    Whisper,
    Popover,
    Dropdown,
    FlexboxGrid
} from 'rsuite';
import {PortalContext} from "../../@Context/PortalContext";
import {sort} from "../../@Utils/Sorting";
import { HeaderSummary } from "../Table";
import {currency, percent, thousands} from "../../@Utils/Format";
import { exportCsv } from "../../@Utils/Export";
import { FaEllipsisV } from 'react-icons/fa';
import {format} from "date-fns";
import moment from 'moment';


const {Column, HeaderCell, Cell} = Table;

const HourlyStats: React.FC = () => {
    const Portal = React.useContext(PortalContext);
    const [data, setData] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [limit, setLimit] = React.useState(100);
    const [page, setPage] = React.useState(1);
    const [action, setAction] = React.useState(0);



    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    /**
     * @param list
     */
    const sortData = (list: any) => {
        return sortColumn && sortType ? sort(list, sortColumn, sortType) : list

    };

    const formatData = (data: any) => {
        return data.map((item: any) => {
            return {
                ...item,
                accepted_percent: Number(item.leads !== 0 ? (item.purchased / item.leads * 100).toFixed(2) : 0),
                date_hour: moment(item.date_hour).format("YYYY-MM-DD HH:mm:ss"),
                leads: Number(parseFloat(item.leads).toFixed(2)),
                purchased: Number(parseFloat(item.purchased).toFixed(2))
            }
        })
    }

    const fetchHourlyData = (noCache = true) => {
        // Clear and mask a table
        setData([]);
        setIsLoading(true);


        const params = {
            start_date: format(Portal.start, "yyyy-MM-dd 00:00:00"),
            end_date: format(Portal.end, "yyyy-MM-dd 23:59:59"),
            time_zone: Portal.timeZone,
            afid: Portal.affiliate_id,
            group_by: 'hour',
            campaign: Portal.campaign ? Portal.campaign : '',
            authorization_key: localStorage.getItem('authorization_key')
        }

        HttpClient
            .get<any>('buyer_stats', params, noCache)
            .then(res => {
                setIsLoading(false);
                setData(formatData(res.data.data))
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    React.useEffect(() => {
        fetchHourlyData();
    }, [Portal.start, Portal.end, Portal.affiliate_id, Portal.timeZone, Portal.campaign]);

    React.useEffect(() => {
        handleSortColumn('date_hour', 'desc');
    },[])

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
    };

    const tableData = Array.isArray(data) ?
        data.filter((v:any, i:any) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        }) : []

    const getSummary = (data: any) => {
        const summary: { [p: string]: number } = {};
        const summarizer = (accumulator: number, currentValue: number) =>
            accumulator + currentValue;

        const summaryFields = [
            "leads",
            "purchased",
            "accepted_percent",
        ];
        summaryFields.forEach((field) => {
            summary[field] = 0;
        });

        summaryFields.forEach((field: string) => {
            let values = data.map((item: { [index: string]: string }): number => {
                return parseFloat(item[field]);
            });
            summary[field] = values.reduce(summarizer, 0) || 0;
        });

        return summary;
    }

    const summaryData = getSummary(Array.isArray(data) ? formatData(data) : [])

    const formatExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        "Date": moment(item.date_hour).format("YYYY-MM-DD HH:mm:ss"),
                        "Leads": thousands(item.leads),
                        "Purchased": thousands(item.purchased),
                        "Accepted Percent": item.accepted_percent
                    }
                })
        );
    };

    const percentSummary = (summaryData.purchased / summaryData.leads) * 100;

    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
                    <Whisper
                        placement="auto"
                        trigger="click"
                        speaker={(
                            {
                                onClose,
                                left,
                                top,
                                className
                            }, ref) => {
                            const handleSelect = (eventKey: any) => {
                                onClose();
                                setAction(eventKey);
                            };
                            return (
                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                    <Dropdown.Menu onSelect={handleSelect}>
                                        <Dropdown.Item
                                            onClick={() => {
                                                exportCsv(
                                                    `Hourly_PubId_${Portal.affiliate_id}_${format(new Date(Portal.start), "MMddyy")}
                                                                _${format(new Date(Portal.end), "MMddyy")}.csv`,
                                                    formatExportData(formatData(data))
                                                )
                                            }}
                                        >
                                            Export
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Popover>
                            );
                        }}
                    >
                        <IconButton appearance="primary" icon={<FaEllipsisV />} />
                    </Whisper>
                    <Table
                        bordered
                        loading={isLoading}
                        cellBordered
                        width={500}
                        height={500}
                        headerHeight={40}
                        style={{marginLeft: '-26px'}}
                        data={Array.isArray(tableData) ? sortData(formatData(tableData)) : []}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        rowHeight={20}
                    >
                        <Column width={100} align="center" flexGrow={2} sortable>
                            <HeaderCell>
                                Date Hour
                            </HeaderCell>
                            <Cell dataKey="date_hour"/>
                        </Column>
                        <Column width={50} align="center" flexGrow={1} sortable>
                            <HeaderCell>
                                <HeaderSummary title="Leads" summary={summaryData.leads} />
                            </HeaderCell>
                            <Cell dataKey="leads"/>
                        </Column>
                        <Column width={50} align="center" flexGrow={1} sortable>
                            <HeaderCell>
                                <HeaderSummary title="Purchased" summary={summaryData.purchased} />
                            </HeaderCell>
                            <Cell dataKey="purchased"/>
                        </Column>
                        <Column width={50} align="center" flexGrow={1} sortable>
                            <HeaderCell>
                                % Accepted
                            </HeaderCell>
                            <Cell dataKey="accepted_percent"/>
                        </Column>
                    </Table>
                    <div style={{ width:400, marginRight: 45 }}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={3}
                            size="xs"
                            layout={['total', '-', 'limit', 'pager']}
                            total={data.length}
                            limitOptions={[10, 30, 50, 100]}
                            limit={limit}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </>
    );
};

export default HourlyStats;