import React from "react";
import { Container, Header, Sidebar, Content } from 'rsuite';
import DailyStatsTable from "./DailyStats";
import AcceptedStatsTable from "./AcceptedStatsTable";
import RejectedStatsTable from "./RejectedStatsTable";
import HourlyStats from "./HourlyStats";


const BuyerPortalStats: React.FC = () => {

    return (
        <div className="show-fake-browser sidebar-page" style={{ marginLeft: 20 }}>
            <Header>
                <h5 style={{ textAlign: 'center' }}>Direct Post Publisher Portal</h5>
            </Header>
            <Container style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Content style={{ width: "88%" }}>
                    <Container>
                        <Container>
                            <DailyStatsTable />
                        </Container>

                        <Container style={{ marginBottom: 50 }}>
                            <AcceptedStatsTable />
                        </Container>

                        <Container>
                            <RejectedStatsTable />
                        </Container>
                    </Container>
                </Content>

                <Sidebar style={{ marginTop: 106, width: 'fit-content' }}>
                    <HourlyStats />
                </Sidebar>
            </Container>
        </div>
    )
}

export default BuyerPortalStats