import React from "react";
import {Nav, Navbar, Button, DateRangePicker, SelectPicker, Toggle} from "rsuite";
import {
    format,
    subDays,
    subMonths,
    startOfMonth,
    lastDayOfMonth,
} from "date-fns";
import {PortalContext} from "../../@Context/PortalContext";
import HttpClient from "../../@Utils/HttpClient";
import {useNavigate} from "react-router-dom";

const TopNavBar: React.FC = () => {
    const Portal = React.useContext(PortalContext);
    const [campaignData, setCampaignData] = React.useState<any[]>([]);
    const [toggleStatus, setToggleStatus] = React.useState(true);
    const [affiliateData, setAffiliateData] = React.useState<any[]>([]);
    const [affiliateBool, setAffiliateBool] = React.useState(false);
    const defaultTimeZone = 'PT';
    // start: format(dates[0], "yyyy-MM-dd 00:00:00"),
    //     end: format(dates[1], "yyyy-MM-dd 23:59:59"),
    /**
     * Proxy for capturing state changes with DateRangePicker
     * Changes update the DataFiltersContext
     */
    const setDates = (dates: any) => {
        // console.log({
        //     start: dates[0],
        //     end: dates[1],
        // })
        Portal.setDateRange({
            start: dates[0],
            end: dates[1],
        });
    };

    /**
     * Custom function for formatting date ranges. Idea is to make
     * it more human readable.
     */
    const formatDates = (value: any, dateFormat: string) => {
        if (!value[0] || !value[1]) {
            return null;
        }
        if (value[0].toString() === value[1].toString()) {
            return (
                <span style={{ paddingRight: 10 }}>
          {format(value[0], "LLL do, yyyy")}
        </span>
            );
        }
        return (
            <span style={{ paddingRight: 10 }}>
        From {format(value[0], "LLL do, yyyy")} to{" "}
                {format(value[1], "LLL do, yyyy")}
      </span>
        );
    };

    const last_month = subMonths(new Date(), 1);

    const handleTimeZoneChange = (timeZone: any) => {
        Portal.setTimeZone(timeZone)
    }

    const fetchCampaignData = (noCache = true, date:any, affiliateId: any) => {
        const params = {
            campaign_id: 0,
            campaign_type: '',
            ping_afid: affiliateId
        }

        HttpClient
            .get<any>('campaigns', params, noCache)
            .then(res => {
                let filteredCampaign = res.data.data.map((item:any) => {
                    return {
                        label: item.description,
                        value: item.campaign_id
                    }
                });
                setCampaignData(filteredCampaign);

            })
            .catch(error => {
                console.log('error', error)
            })
    }
    const dates = [Portal.start, Portal.end]

    const fetchAffiliateAccounts = (noCache = true) => {
        // Clear and mask a table
        setAffiliateData([]);

        const params = {
            op: 'affiliate_access',
            email: localStorage.getItem('email'),
            authorization_key: localStorage.getItem('authorization_key')
        }

        HttpClient
            .get<any>('https://admin.admediary.com/api/reportsexternal.php?', params, noCache)
            .then(res => {
                if (res.data.response_data.row_count > 1 ) {
                    let filteredAffiliate = res.data.response_data.data.map((item:any) => {
                        return {
                            label: item.affiliate_name + " - " + item.affiliate_id,
                            value: item.affiliate_id
                        }
                    });
                    setAffiliateBool(true);
                    setAffiliateData(filteredAffiliate);                   
                } else {
                    setAffiliateBool(false);
                }       
            })
            .catch(error => {
                console.log('error', error)
            })
    }
    React.useEffect(() => {
        fetchCampaignData(true, dates, Portal.affiliate_id);
        fetchAffiliateAccounts(true);
    },[Portal.affiliate_id])

    const handleCampaignChange = (campaign: any) => {
        // console.log(campaign)
        Portal.setCampaign(campaign)
    }

    const handleAffiliateChange = (affiliate: any) => {
        // console.log(affiliate)
        Portal.setAffiliateId(affiliate)
    }

    if (toggleStatus) {
        document.body.classList.add('rs-theme-dark');
        document.body.classList.remove('rs-theme-light');
    } else {
        document.body.classList.add('rs-theme-light');
        document.body.classList.remove('rs-theme-dark');
    }

    // console.log('portal campaign', Portal.campaign)

    const handleToggleChange = () => {

        if (toggleStatus) {
            document.body.classList.add('rs-theme-dark');
            document.body.classList.remove('rs-theme-light');
            setToggleStatus(false);
        } else {
            document.body.classList.add('rs-theme-light');
            document.body.classList.remove('rs-theme-dark');
            setToggleStatus(true);
        }

    }

    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.clear();
        return navigate('/auth/login');
    }

    const compare = (a:any, b:any) => {
        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();
      
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }

    return (
        <Navbar appearance="subtle" style={{ background: "#023066", display: "flex" }}>
            <Nav style={{margin: 15}}>
                <DateRangePicker
                    size="md"
                    renderValue={formatDates}
                    block={true}
                    value={[Portal.start, Portal.end]}
                    // defaultValue={[Portal.start, Portal.end]}
                    // defaultCalendarValue={[startOfMonth(new Date()), new Date()]}
                    onChange={setDates}
                    cleanable={false}
                    ranges={[
                        {
                            label: "Yesterday",
                            value: [subDays(new Date(), 1), subDays(new Date(), 1)],
                        },
                        {
                            label: "Today",
                            value: [new Date(), new Date()],
                        },
                        {
                            label: "Last 7 days",
                            value: [subDays(new Date(), 7), subDays(new Date(), 1)],
                        },
                        {
                            label: "This Month",
                            value: [startOfMonth(new Date()), new Date()],
                        },
                        {
                            label: "Last Month",
                            value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
                        },
                    ]}
                />
            </Nav>

            <Nav style={{margin: 15}}>
                <SelectPicker
                    data={[
                        {label: 'PT', value: 'PT'},
                        {label: 'MT', value: 'MT'},
                        {label: 'CT', value: 'CT'},
                        {label: 'ET', value: 'ET'},
                    ]}
                    defaultValue={defaultTimeZone}
                    onChange={handleTimeZoneChange}
                    placeholder={"Time Zone"}
                    style={{ width: "90%" }}
                />
            </Nav>

            <Nav style={{margin: 15}}>
                <SelectPicker
                    data={Array.isArray(campaignData) ? campaignData : []}
                    defaultValue={Portal.campaign}
                    className={"campaign-dropdown"}
                    onChange={handleCampaignChange}
                    placeholder={"Campaign"}
                    style={{ width: "100%" }}
                    sort={() => {
                        return (a, b) => {
                          return compare(a.label, b.label);
                        };
                      }}
                />
            </Nav>

            <Nav pullRight style={{margin: 15}}>
                <span style={{color: "white", marginRight: 15, fontSize: 18}}>
                    {affiliateBool ? (
                        <SelectPicker
                            data={Array.isArray(affiliateData) ? affiliateData : []}
                            defaultValue={Portal.affiliate_id}
                            className={"affiliate-dropdown"}
                            onChange={handleAffiliateChange}
                            placeholder={"Affiliate"}
                            style={{ width: "40%" }}
                            sort={() => {
                                return (a, b) => {
                                    console.log(a);
                                  return compare(a.label, b.label);
                                };
                              }}
                        />
                        
                        ) : `${Portal.affiliate_id} - ${localStorage.getItem('affiliate_name')}`
                    }
                </span>
                <span style={{color: "white", marginRight: 15, fontSize: 18}}> - {localStorage.getItem('email')} </span>
                <Toggle size="md" onChange={handleToggleChange} />
                <Button onClick={handleLogout} style={{marginLeft: 15, marginRight: 15}}>Log Out</Button>
            </Nav>
        </Navbar>
    );
};

export default TopNavBar;