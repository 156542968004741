import React from "react";
import HttpClient from "../@Utils/HttpClient";
import {
    Container,
    Header,
    Content,
    Nav,
    Navbar,
    FlexboxGrid,
    Panel,
    Form,
    ButtonToolbar,
    Button,
    Footer,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useSearchParams } from "react-router-dom";

let initialValues = {
    email: ''
  }





interface ResetProps {
    children?: React.ReactNode;
  }

  


  
  const Reset: React.FC<ResetProps> = (props) => {



    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [authenticated, setAuthenticated] = React.useState(localStorage.getItem("authenticated"));
    const [emailSent, setEmailSent] = React.useState(false);

    const handleChange = (e:any) => {
        document.getElementById('email')?.classList.remove('error');
        
        setEmail(e);
        
    }

    const onCancel = (e:any) => {
        navigate('/');
    }

    const onSubmit = (e:any) => {
        e.preventDefault();



        HttpClient
        .get<any>('https://admin.admediary.com/api/reportsexternal.php?op=password_reset_request&email='+email)
        .then(res => {
            if (res.data.response_data.row_count > 0) {
                setEmailSent(true)

                setTimeout(() => {
                    navigate('/');
                }, 6000);
                
            } else {
                document.getElementById('email')?.classList.add('error');

            }
            
        })
        .catch(error => {
            console.log('error', error)
        })
    };

    return (
        <div>
            <Container>
                <Header>
                    {/* <Navbar appearance="inverse" 
                        style={{ backgroundColor: "green" }}>
                        <Navbar.Brand>GeeksforGeeks</Navbar.Brand>
                        <Nav>
                            <Nav.Item>Home</Nav.Item>
                            <Nav.Item>About</Nav.Item>
                            <Nav.Item>Practice</Nav.Item>
                            <Nav.Menu title="Tutorials">
                                <Nav.Item>Data Structures</Nav.Item>
                                <Nav.Item>Algorithms</Nav.Item>
                                <Nav.Item>Web Development</Nav.Item>
                            </Nav.Menu>
                        </Nav>
                        <Nav pullRight>
                            <Nav.Item>Contact</Nav.Item>
                        </Nav>
                    </Navbar> */}
                </Header>
                <div style={{ textAlign: "center" }}>
                    <h2>Portal Reset</h2>
                    {/* <h4 style={{ color: "green" }}>
                        React Suite Container Login Page Layout
                    </h4> */}
                </div>
                <Content>
                    <FlexboxGrid justify="center" 
                        style={{ margin: 20 }}>
                        <FlexboxGrid.Item>
                            <Panel header={<h3>Password Reset</h3>} bordered>
                                <Form>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Email
                                        </Form.ControlLabel>
                                        <Form.Control name="email" id="email"
                                            type="email"  onChange={handleChange} required />
                                        <Form.HelpText tooltip>
                                            Required
                                        </Form.HelpText>
                                    </Form.Group>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button
                                                appearance="primary"
                                                onClick={onSubmit}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                appearance="ghost"
                                                onClick={onCancel}
                                                >Cancel</Button>
                                        </ButtonToolbar>
                                        {emailSent ? (
                                            <span style={{color: 'red'}}>**An e-mail has been sent with a link to reset your password. You will now be redirected to the login page!**</span>
                                        ) : ''}
                                    </Form.Group>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <Footer style={{ textAlign: "center", margin: 20 }}>
                    © {(new Date().getFullYear())} Admediary
                </Footer>
            </Container>
        </div>
    );
}
  
  export default Reset;