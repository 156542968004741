import AdmediaryConfig from "../AdmediaryConfig";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

/**
 * Get base client URL
 * Determine if we're using the local HTTP proxy or direct client request
 * the local proxy will be a relative path with NodeJS
 *
 * @return string
 */
export const getBaseApiUrl = (): string => AdmediaryConfig.API_BASE;

/**
 * Format response data by key value
 *
 * @param {object[]} data  Response data
 */
/*function formatData(data: object[]) {
  if (!(data && data.length > 0)) {
    return data;
  }

  return data.map((row: any) => {
    const newRow: any = {};
    Object.keys(row).forEach((item) => {
      const value = row[item] || "";

      // Format value

      newRow[item] = value;
    });
    return newRow;
  });
}*/

const handleSuccess = (response: AxiosResponse) => {
  // console.log('ApiClient: SUCCESS');
  // console.log(response);
  // if (typeof response.data !== "object") {
  //   throw response.data;
  // }

  // if (response.data.response_code === 0) {
  //   throw response.data.response_error;
  // }

  // response.data.data = formatData(response.data.data);

  return response;
}

const handleError = (error: any) => {
  // console.log('ApiClient: Error');
  // console.log(error);
  switch (error.response.status) {
    case 401:
      // redirectTo(document, '/')
      break;
    case 404:
      // redirectTo(document, '/404')
      break;
    default:
      // redirectTo(document, '/500')
      break;
  }

  return Promise.reject(error);
}

// const redirectTo = (document, path) => {
//   document.location = path
// };

const client = axios.create({
  baseURL: getBaseApiUrl(),
  responseType: 'json' as const,
  headers: {
    'Content-Type': 'application/json',
    // TODO Add and config authorization
    // ...(localStorage.getItem("auth.id_token") && {
    //   Authorization: `Bearer ${localStorage.getItem("auth.id_token")}`,
    // }),
  },
  timeout: 30000, // 30 seconds
});

/**
 * Intercept request to catch auth issues
 */
/*
client.interceptors.request.use((config) => {
  const accessToken: string | null = localStorage.getItem("auth.id_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});
*/

/**
 * Intercept responses to format data by field name
 */
client.interceptors.response.use(handleSuccess, handleError);

/**
 * Send GET request to API
 * @param path  Relative path to  API Controller/Action, e.g. offer, campaign/12
 * @param params
 * @param noCache
 */
const get = async function <TResponse>(path: string, params?: object, noCache: boolean = false): Promise<TResponse> {
  if (noCache) {
    params = {
      ...params,
      timestamp: Date.now(),
    };
  }

  return new Promise<TResponse>((resolve, reject) => {
    client
      .get(path, {params})
      .then((response: any) => {
        resolve(response as TResponse);
      })
      .catch((response: any) => {
        reject(response);
      });
  });
};

/**
 * Send POST request to API
 * @param path  Relative path to  API Controller/Action, e.g. offer, campaign/12
 * @param data
 * @param config Request configuration
 */
const post = async <TRequest, TResponse>(
  path: string,
  data: TRequest,
  config?: AxiosRequestConfig
): Promise<TResponse> => {
  return new Promise<TResponse>((resolve, reject) => {
    client
      .post<TResponse>(path, data, config)
      .then((response: any) => {
        resolve(response as TResponse);
      })
      .catch((response: any) => {
        reject(response);
      });
  });
};

/**
 * Send PUT request to API
 * @param path  Relative path to  API Controller/Action, e.g. offer, campaign/12
 * @param params
 * @param config Request configuration
 */
const put = async function <TResponse>(path: string, params?: object, config?: AxiosRequestConfig): Promise<TResponse> {
  return new Promise<TResponse>((resolve, reject) => {
    client
      .put(path, params, config)
      .then((response: any) => {
        resolve(response as TResponse);
      })
      .catch((response: any) => {
        reject(response);
      });
  });
};

/**
 * Send PATCH request to API
 * @param path  Relative path to  API Controller/Action, e.g. offer, campaign/12
 * @param params
 * @param config Request configuration
 */
const patch = async function <TResponse>(path: string, params?: object, config?: AxiosRequestConfig): Promise<TResponse> {
  return new Promise<TResponse>((resolve, reject) => {
    client
      .patch(path, params, config)
      .then((response: any) => {
        resolve(response as TResponse);
      })
      .catch((response: any) => {
        reject(response);
      });
  });
};

/**
 * Send DELETE request to API
 * @param path  Relative path to  API Controller/Action, e.g. offer, campaign/12
 * @param config Request configuration
 */
const remove = async function <TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse> {
  return new Promise<TResponse>((resolve, reject) => {
    client
      .delete(path, config)
      .then((response: any) => {
        resolve(response as TResponse);
      })
      .catch((response: any) => {
        reject(response);
      });
  });
};

const HttpClient = {
  get,
  post,
  put,
  patch,
  delete: remove
};

export default HttpClient;