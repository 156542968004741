
import React from "react";
import { Outlet } from "react-router-dom";
// import AuthNavbar from "./AuthNavbar";
// import AuthFooter from "./AuthFooter";

const Auth = () => {
    return (
        <React.Fragment>

            <Outlet />

        </React.Fragment>
    );
}
export default Auth;