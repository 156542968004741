import React, { useContext }  from "react";
import {Container, Content} from "rsuite";
import BuyerPortalStats from "../@Pages/BuyerPortalStats";
// import SideBar from "./Dashboard/Sidebar/SideBar";
import TopNavBar from "./Dashboard/TopNavBar";
import 'rsuite/dist/rsuite.min.css';

const sidebar_styles: any = {
  background: "#021b3b",
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  position: 'fixed',
  width: 243,
  zIndex: 1
};

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = (props) => {


  /***
   * Helper function for setting active class if the current location
   * has a base that matches the string provided.
   */
  return (
    <Container>
      {/*<SideBar styles={sidebar_styles}/>*/}
      <Container className="content-container" >
        <TopNavBar />
        <Content style={{position: 'relative', maxWidth: '91%', height: 'auto'}}>{props.children}</Content>
      </Container>
    </Container>
  );
};

export default Dashboard;
