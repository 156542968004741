import React from "react";
import Dashboard from "../@Components/Dashboard";
import BuyerPortalStats from "../@Components/Stats/BuyerPortalStats";

// const {allowedMaxDays} = DateRangePicker;

const BuyerPortalStatsPage: React.FC = () => {
  return (
    <Dashboard>
      {/*<div>test</div>*/}
        {/* <BuyerPortalStats /> */}
        <BuyerPortalStats />
      {/* <SummaryCards summary={summary} filters={filters}/> */}

    </Dashboard>
  );
};

export default BuyerPortalStatsPage;
