import React from "react";
import HttpClient from "../@Utils/HttpClient";
import {
    Container,
    Header,
    Loader,
    Content,
    Nav,
    Navbar,
    FlexboxGrid,
    Panel,
    Form,
    ButtonToolbar,
    Button,
    Footer,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PortalContext } from "../@Context/PortalContext";

let initialValues = {
    email: ''
  }





interface ResetProps {
    children?: React.ReactNode;
  }

  


  
  const ResetVerify: React.FC<ResetProps> = (props) => {



    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [authenticated, setAuthenticated] = React.useState(localStorage.getItem("authenticated"));
    const [password, setPassword] = React.useState("");
    const [passwordConfirm, setPasswordConfirm] = React.useState("");
    const [searchParams] = useSearchParams();
    const [verified, setVerified] = React.useState(false);
      const Portal = React.useContext(PortalContext);


    const handleChange = (e:any) => {
        document.getElementById('email')?.classList.remove('error');
        
        setEmail(e);
        
    }

    const handlePass = (e:any) => {
        document.getElementById('password')?.classList.remove('error');
        setPassword(e);
        
    }

    const handlePassConfirm = (e:any) => {
        document.getElementById('confirmPassword')?.classList.remove('error');
        setPasswordConfirm(e);
        
    }

    HttpClient
        .get<any>('https://admin.admediary.com/api/reportsexternal.php?op=password_reset_verify&uid='+searchParams.get("uid")+'&email='+searchParams.get("email"))
        .then(res => {
            if (res.data.response_data.row_count > 0) {
                
            localStorage.setItem('uid', String(searchParams.get("uid")));
            localStorage.setItem('email', String(searchParams.get("email")));
                Portal.setAffiliateId(searchParams.get("affiliate_id"));
            setVerified(true);

                setTimeout(() => {
                    navigate('../resetsubmit');
                }, 500);
                
            } else {
                setVerified(false);

                setTimeout(() => {
                    navigate('../reset');
                }, 6000);
                
            }
            
        })
        .catch(error => {
            console.log('error', error)
        })

    const onSubmit = (e:any) => {
        e.preventDefault();



        
    };

    return (
        <div>
            <Container>
                <Header>
                    {/* <Navbar appearance="inverse" 
                        style={{ backgroundColor: "green" }}>
                        <Navbar.Brand>GeeksforGeeks</Navbar.Brand>
                        <Nav>
                            <Nav.Item>Home</Nav.Item>
                            <Nav.Item>About</Nav.Item>
                            <Nav.Item>Practice</Nav.Item>
                            <Nav.Menu title="Tutorials">
                                <Nav.Item>Data Structures</Nav.Item>
                                <Nav.Item>Algorithms</Nav.Item>
                                <Nav.Item>Web Development</Nav.Item>
                            </Nav.Menu>
                        </Nav>
                        <Nav pullRight>
                            <Nav.Item>Contact</Nav.Item>
                        </Nav>
                    </Navbar> */}
                </Header>
                <div style={{ textAlign: "center" }}>
                    {verified ? (
                        <h2>Verifying credentials...
                            <Loader></Loader>
                        </h2>
                    ) : <h2>Password reset link timed out! Please resubmit your email address.</h2>}
                    {/* <h4 style={{ color: "green" }}>
                        React Suite Container Login Page Layout
                    </h4> */}
                </div>
                {/* <Content>
                    <FlexboxGrid justify="center" 
                        style={{ margin: 20 }}>
                        <FlexboxGrid.Item>
                            <Panel header={<h3>Password Reset</h3>} bordered>
                                <Form>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Email
                                        </Form.ControlLabel>
                                        <p>{searchParams.get('email')}</p>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Password
                                        </Form.ControlLabel>
                                        <Form.Control name="password" id="password"
                                            type="password" onChange={handlePass} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Confirm Password
                                        </Form.ControlLabel>
                                        <Form.Control name="confirmpassword" id="confirmpassword"
                                            type="password" onChange={handlePassConfirm} />
                                    </Form.Group>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button appearance="primary" onClick={onSubmit}>
                                                Submit
                                            </Button>
                                            <Button appearance="ghost" 
                                                >Cancel</Button>
                                        </ButtonToolbar>
                                    </Form.Group>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <Footer style={{ textAlign: "center", margin: 20 }}>
                    © Admediary
                </Footer> */}
            </Container>
        </div>
    );
}
  
  export default ResetVerify;